#footer-links a{
    text-decoration: none;
    color: white;
}

.navbar-brand{
    font-family: 'Rubik Doodle Shadow';
}

.font{
    font-family: 'Rubik Doodle Shadow';
}

.header-text{
    font-family: 'Kaushan Script';
}