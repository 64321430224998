.back-to-top-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    display: none;
    padding: 10px;
    background-color: #0d6efd;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    opacity: 0.8;
    transition: opacity 0.3s ease;
    z-index: 1000;
  }
  
  .back-to-top-button.visible {
    display: block;
  }
  
  .back-to-top-button:hover {
    opacity: 1;
  }
  